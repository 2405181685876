@import "./index.scss";

.container {
    max-width: 1300px;
    margin: auto;
    padding: 20px;
}

.mt-5v {
    margin-top: 5vh;
}

.pt-124px {
    padding-top: 124px;
}

.btn-four-blue{
    color: $colorFour !important;
    border: 1px solid $colorFour !important;
    font-family: RidleyGroteskMedium !important;
    text-transform: none !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
}

.btn-primary-white {
    color: #fff !important;
    border: 1px solid #fff !important;
    font-family: RidleyGroteskMedium !important;
    text-transform: none !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
}

.btn-contained-blue{
    color: #ffffff !important;
    border-radius: 4px !important;
    background-color: $colorFour !important;
    font-family: RidleyGroteskMedium !important;
    text-transform: none !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 16px !important;
}

.margin-0{
    margin: 0px !important;
}

.mt-0{
    margin-top: 0px !important;
}

.py-60px{
    padding-top: 60px;
    padding-bottom: 60px;
}

/* Modal Home */
.home-video-modal{
    .MuiDialog-container{
        max-width: 90%;
        margin: auto;

        .MuiPaper-root{
            width: max-content;
            max-width: none;
            background-color: transparent;
            box-shadow: none;

            .MuiDialogActions-root{
                padding: 0px;

                .close-button{
                    width: max-content;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 0px;

                    svg{
                        color: #ffffff;
                        font-size: 3vw;

                        @media(max-width: 767px){
                            font-size: 6vw;
                        }
                        @media(max-width: 575px){
                            font-size: 10vw;
                        }
                    }
                }
            }

            .MuiDialogContent-root{
                padding: 0px;
            }
        }
    }
}
/* Fin Modal Home */

@media(min-width: 901px){
   /* Height by screen viewport */
    .element-view-height{
        height: calc(100vh - 124px) !important;
    }

    .margin-top-navbar{
        margin-top: 124px;
    }
     /* End Slider Responsive *

    /* Slider Responsive */
    // .blockContainerMobile{
    //     display: none !important;
    // }

    .pt-lg-124px {
        padding-top: 124px;
    }
}

@media(max-width: 900px){
    .element-view-height-mobile{
        height: calc(100vh - 132px) !important;
    }

    .margin-top-navbar{
        margin-top: 132px;
    }
}
