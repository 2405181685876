/* ESTILOS HEADER */
.header {
    width: 100%;
    position: fixed;
    z-index: 999;
    background: #fff;
    top: 0;

    @media(min-width: 901px){
        .header-no-product{
            max-width: calc(100vw - 300px);
            width: calc(100vw - 300px);
        }
    
        .header-product{
            max-width: calc(100vw - 20.76vw);
            width: calc(100vw - 20.76vw);
        }
    }

    @media(max-width: 900px){
        .header-product{
            padding: 20px 5.34% !important;
        }
    }

    @media(min-width: 1200px){
        .container > .MuiGrid-root{
            position: relative;
    
            p.help{
                position: absolute;
                left: 0;
                right: 0;
            }
            
            .margin-top-20px{
                margin-top: 20px;
            }
        }
    }

    .help {
        font-size: 16px;
        color: $tertiary;
        font-family: RidleyGroteskLight;
        text-align: center;
        margin-top: 10px;
    }

    .help strong {
        font-family: RidleyGroteskBlack;
    }

    .menu-icon {
        color: $primary;
        font-size: 30px;
    }

    .menu-button {
        margin-top: -9px;
    }

    .logo {
        width: 316.61px;
        height: 44px;
    }

    .icon-social {
        line-height: 30px;
        font-size: 19px;
        color: $primary;
        width: 43px;
        margin-top: 10px;
    }
}

.menu-dialog {
    .MuiGrid-root {
        max-width: 800px;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    a {
        color: #fff;
        font-size: 30px;
        text-decoration: none;
        font-family: RidleyGroteskBlack;
    }

    .MuiDialog-paper {
        padding: 20px;
    }

    .close-icon {
        font-size: 40px;
        color: #fff;
    }
}

/*
 ************************************************************************************************************************************************************************
*/

/* ESTILOS FOOTER */

.carousel-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .responsive-second-column{
        padding-right: 150px !important;
    }

    .responsive-first-column{
        padding-left: 150px !important;
    }

    .footer-title{
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .carousel1-title{
        color: #273583;
        font-family: RidleyGroteskBlack;
        font-size: 7vh !important;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 7vh !important;
    }

    .grid-container{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .footer-phone{
        margin-bottom: 6vh !important;
    }
}

.footer, .carousel-footer{
    .caja-logo {
        margin-bottom: 71px;
    }
    
    .logo-stm {
        width: 285px !important;
    }
    
    .facebook {
        height: 20px;
        width: 20px;
        color: #3362AC;
        margin-right: 2%;
    }
    
    .twitter {
        height: 20px;
        width: 20px;
        color: #3362AC;
        margin-right: 2%;
    }
    
    .instagram {
        height: 20px;
        width: 20px;
        color: #3362AC;
        margin-right: 2%;
    }

    .footer-text-blue{
        color: $tertiary;
        margin: 0;
        font-size: 16px;;
    }

    .footer-text-bold{
        font-family: RidleyGroteskBlack;
    }

    .footer-text{
        font-family: RidleyGroteskRegular;
    }

    .footer-phone{
        margin-top: 16px;
        margin-bottom: 68px;
    }

    .btn-footer{
        color: $colorFive;
        border-color: $colorFive;
        font-size: 16px;
        height: 60px;
        width: 180px;
        text-transform: capitalize;
        font-family: RidleyGroteskMedium;
    }

    @media(max-width: 899px){
        .btn-footer{
            color: $colorFive;
            border-color: $colorFive;
            font-size: 16px;
            height: 50px;
            width: 220px;
        }

        .responsive-first-column{
            padding-right: 5.4%;
            padding-left: 5.4%;
            padding-bottom: 15.8%;
        }

        .responsive-second-column{
            padding-left: 5.4%;
            padding-right: 5.4%;
        }

        .grid-container{
            padding-bottom: 11%;
            padding-top: 16%;
        }

        .caja-logo {
            margin-bottom: 9%;
        }

        .footer-phone {
            margin-bottom: 10.6%;
        }
    }

    @media(max-width: 550px){
        .logo-stm{
            width: 64.5%;
        }
    }
}
