.products{
    /* Lista productos */
    .block1-products{
        @media(max-width: 1199px){
            img{
                width: 90px;
                height: auto;
                object-fit: cover;
                object-position: center;
            }
    
            h1{
                font-size: 40px;
                line-height: 40px;
            }
        }
    }

    .block2-products {
        @media(max-width: 991px){
            .image-container{
                width: 100%;
                max-width: 90vw;
                padding-left: 2.09vw;
                padding-right: 2.09vw;
    
                img{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    @media(min-width: 901px){
        .px-lg-150px{
            padding-left: 150px;
            padding-right: 150px;
        }
        .px-lg-10-42{
            padding-left: 10.42%;
            padding-right: 10.42%;
        }
    }

    @media(max-width: 900px){
        .align-content-md-end{
            align-content: flex-end;
        }

        .mb-md-40px{
            margin-bottom: 40px;
        }

        .px-md-20px{
            padding-left: 20px;
            padding-right: 20px;
        }

        .px-md-5-34{
            padding-left: 5.34%;
            padding-right: 5.34%;
        }

        .py-md-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .swiper-container-vertical>.swiper-pagination-bullets{
            display: none;
        }

        .block1-products, .block2-products, .block3-products, .block4-products, .block5-products, .block6-products{
            height: auto;
        }
    }

    @media(max-width: 576px){
        .px-xs-10px{
            padding-left: 10px;
            padding-right: 10px;
        }

        .py-xs-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .align-content-xs-end{
            align-content: flex-end;
        }

        .mb-xs-40px{
            margin-bottom: 40px;
        }

        .h-xs-auto{
            height: auto !important;
        }
    }
    /* Fin Lista productos */

    /* Productos individuales */
    @media(max-width: 900px){
        /* Producto Anti Okupas */
        .block1-product-paok{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 69.33vw;
    
            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 80vw;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
    
                img{
                    width: 90.67vw;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;
                    top: 85vw;
                }
            }
        }
    
        .block2-product-paok{
            padding-top: 57.6vw;
            padding-bottom: 16vw;
            background-color: #FFFFFF;
    
            img.key-image{
                width: 52.53vw;
            }
    
            img.icon-product{
                width: 6.67vw;
            }
    
            span{
                font-size: 6.93vw;
                line-height: 7vw;
            }
    
            button{
                height: 50px;
                width: 220px;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: center;
    
                .grid-item:first-child{
                    margin-right: 0px;
                    margin-bottom: 16vw;
                }
    
                .list-container{
                    .icon-product-container{
                        margin-right: 2.66vw;
                        margin-top: 0.8vw;
                    }
                }
    
                .list-container.list-container-notlast{
                    margin-bottom: 2.66vw;
                }
    
                .list-container.list-container-last{
                    margin-bottom: 10.66vw;
                }
    
            }
        }
    
        .block3-product-paok{
            padding-top: 16.53vw;
            padding-bottom: 16vw;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 74.4vw;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }
            }
            
            .vector-item{
                margin-bottom: 0px;

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

    
            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }
    
        .block4-product-paok{
            padding-top: 16vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                align-items: center;
    
                .grid-item:first-child{
                    width: 100%;
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
    
                .grid-item{
    
                    h2{
                        font-size: 8vw;
                        line-height: 9vw;
                        margin-bottom: 8vw;
                        width: 64vw;
    
                        span.paragraph-thin{
                            font-size: 4.25vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.5vw;
                    }
                }
    
                img{
                    width: 78.67vw;
                    object-fit: cover;
                    margin-bottom: 16vw;
                }
            }
        }
    
        .block5-product-paok{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 10.65vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Anti Okupas */

        /* Producto Anti Okupas Electrónica */
        .block1-product-paok-elec{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 69.33vw;
    
            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 80vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 80vw;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
    
                img{
                    width: 90.67vw;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;
                    top: 113vw;
                }
            }
        }

        .block2-product-paok-elec{
            padding-top: 57vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 11.73vw;
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    margin-bottom: 10.66vw;
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }

                .desktop-display{
                    display: none !important;
                }
            }
        }

        .block3-product-paok-elec{
            padding-top: 16.53vw;
            padding-bottom: 16vw;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 59.46vw;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }
            }
            
            .vector-item{
                margin-bottom: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

    
            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-paok-elec{
            padding-top: 13.33vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 84vw;
                    max-width: 84vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 59vw;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block5-product-paok-elec{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 10.65vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Anti Okupas Electrónica */

        /* Producto Reja */
        .block1-product-reja{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 69.33vw;
    
            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 81vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 30vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 34vw;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: -5vw;
                    top: 32vw;
                    max-width: 91vw;
                    overflow: hidden;
                }

                img{
                    width: 112%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .block2-product-reja{
            padding-top: 48vw;
            padding-bottom: 0px;
            background-image: none;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 16vw;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }
            }

            .list-container{
                background-image: url('../../../assets/uploads/productReja/Fondo_reja.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 52.26vw;
                padding-bottom: 52.26vw;
            }
            
            .vector-item{
                margin-bottom: 0px;
                align-items: flex-start;
                max-width: 100%;
                width: 100%;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                    width: 100%;
                    max-width: 100%;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }

    
            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block3-product-reja{
            padding-top: 16vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 89.87vw;
                    max-width: 89.87vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 51vw;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block4-product-reja{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 5.33vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Reja */

        /* Producto Door */
        .block1-product-door{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 81vw;
    
            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 81vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 30vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 69vw;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: -11vw;
                    top: 49vw;
                    max-width: 105.7vw;
                    overflow: hidden;
                    margin: auto;
                    width: 105.7vw;
                }

                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .block2-product-door{
            padding-top: 66vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 11.73vw;
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    margin-bottom: 10.66vw;
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }
            }
        }

        .block3-product-door{
            padding-top: 16.53vw;
            padding-bottom: 0vw;
            background-image: none;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 16vw;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }
            }

            .list-container{
                background-image: url('../../../assets/uploads/productDoor/Fondo_door_2.jpeg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 116.26vw;
                padding-bottom: 16vw;
            }
            
            .vector-item{
                margin-bottom: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

            .vector-item-1, .vector-item-2{
                max-width: 62vw;
            }

            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                margin-left: 0px;

                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-door{
            padding-top: 13.33vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 84vw;
                    max-width: 84vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    br{
                        display: none;
                    }
                    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 100%;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block5-product-door{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 10.65vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Door */

        /* Producto Gravity */
        .block1-product-gravity{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 105vw;
    
            .title-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 81vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 0px !important;
                margin-bottom: 3vw !important;
                margin-right: 1.86vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 86vw;
            }

            br{
                display: none;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: -11vw;
                    top: 56vw;
                    max-width: 105.7vw;
                    overflow: hidden;
                    margin: auto;
                    width: 105.7vw;
                }

                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .block2-product-gravity{
            padding-top: 13vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 5.33vw;
                    width: 45vw;

                    span{
                        font-size: 4.26vw;
                    }
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    width: 87vw;
                    margin-bottom: 10.66vw;
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }
            }
        }

        .block3-product-gravity{
            padding-top: 16vw;
            padding-bottom: 16vw;
            background-image: url('../../../assets/uploads/productGravity/grav_fondo_3_2.jpeg');
            background-position: center;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 80.8vw !important;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }
            }

            .img-vector-desktop{
                display: none;
            }

            .vector-item{
                margin-bottom: 0px;
                width: 100%;
                margin-top: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

            .vector-item-1, .vector-item-2{
                max-width: 81vw;
            }

            .vector-item-3{
                max-width: 71vw;
            }

            .vector-item-4{
                max-width: 88vw;
            }

            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                margin-left: 0px;

                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-gravity{
            padding-top: 13.33vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 84vw;
                    max-width: 84vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    br{
                        display: none;
                    }
                    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 100%;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block5-product-gravity{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 10.65vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Gravity */

        /* Producto Mot */
        .block1-product-mot{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 82vw;
    
            .title-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 81vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 0px !important;
                margin-bottom: 3vw !important;
                margin-right: 1.86vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 86vw;
            }

            br{
                display: none;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 62vw;
                    max-width: 105.7vw;
                    overflow: hidden;
                    margin: auto;
                    width: 90vw;
                    
                    img{
                        width: 100%;
                        height: auto;
                        position: initial;
                        right: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                    }
                }

            }
        }

        .block2-product-mot{
            padding-top: 26.13vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 5.33vw;
                    width: 45vw;

                    span{
                        font-size: 4.26vw;
                    }
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    width: 87vw;
                    margin-bottom: 10.66vw;

                    .span-thin{
                        color: $colorFour;
                    }

                    .span-bold{
                        color: $colorFour;
                        font-family: RidleyGroteskBlack;
                    }
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }
            }

            .desktop{
                display: none;
            }
        }

        .block3-product-mot{
            padding-top: 16vw;
            padding-bottom: 0vw;
            background-image: none;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 16vw !important;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }

            }
            
            .list-container{
                background-image: url('../../../assets/uploads/productMot/Fondo_mot.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 34.66vw;
                padding-bottom: 34.66vw;

                .vector-item-mot-pieza{
                    display: none;
                }
            }

            .img-vector-desktop{
                display: none;
            }

            .vector-item{
                margin-bottom: 0px;
                width: 100%;
                margin-top: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

            .vector-item-1, .vector-item-2{
                max-width: 81vw;
            }

            .vector-item-3{
                max-width: 71vw;
            }

            .vector-item-4{
                max-width: 88vw;
            }

            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                margin-left: 0px;

                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-mot{
            padding-top: 13.33vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 84vw;
                    max-width: 84vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    br{
                        display: none;
                    }
                    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 100%;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block5-product-mot{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 5.33vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Mot */

        /* Producto Alarma */
        .block1-product-alarma{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 82vw;
    
            .title-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }

            h1.small-title{
                font-size: 8.53vw;
                margin-bottom: 1vw;
                margin-left: 20.5vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 81vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 0px !important;
                margin-bottom: -3vw !important;
                margin-right: 1.86vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 68vw;
            }

            br{
                display: none;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 62vw;
                    max-width: 105.7vw;
                    overflow: hidden;
                    margin: auto;
                    width: 90vw;
                    
                    img{
                        width: 100%;
                        height: auto;
                        position: initial;
                        right: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                    }
                }

            }
        }

        .block2-product-alarma{
            padding-top: 26.13vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 5.33vw;
                    width: 45vw;

                    span{
                        font-size: 4.26vw;
                    }
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    width: 87vw;
                    margin-bottom: 10.66vw;

                    .span-thin{
                        color: $colorFour;
                    }

                    .span-bold{
                        color: $colorFour;
                        font-family: RidleyGroteskBlack;
                    }
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }
            }

            .desktop{
                display: none;
            }
        }

        .block3-product-alarma{
            padding-top: 16vw;
            padding-bottom: 0vw;
            background-image: none;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 16vw !important;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }

            }
            
            .list-container{
                background-image: url('../../../assets/uploads/productMot/Fondo_mot.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 34.66vw;
                padding-bottom: 34.66vw;

                .vector-item-mot-pieza{
                    display: none;
                }
            }

            .img-vector-desktop{
                display: none;
            }

            .vector-item{
                margin-bottom: 0px;
                width: 100%;
                margin-top: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

            .vector-item-1, .vector-item-2{
                max-width: 81vw;
            }

            .vector-item-3{
                max-width: 71vw;
            }

            .vector-item-4{
                max-width: 88vw;
            }

            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                margin-left: 0px;

                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-alarma{
            padding-top: 13.33vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 84vw;
                    max-width: 84vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    br{
                        display: none;
                    }
                    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 100%;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block5-product-alarma{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
    
                        span.paragraph-thin{
                            font-size: 5.33vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Alarma */

        /* Producto Conan */
        .block1-product-conan{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 71vw;
    
            .title-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }

            h1.small-title{
                font-size: 8.53vw;
                margin-bottom: 1vw;
                margin-left: 20.5vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 83vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 15vw !important;
                margin-bottom: -1vw !important;
                margin-right: 1.86vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 72vw;
            }

            br{
                display: none;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 62vw;
                    max-width: 105.7vw;
                    overflow: hidden;
                    margin: auto;
                    width: 82vw;
                    
                    img{
                        width: 100%;
                        height: auto;
                        position: initial;
                        right: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                    }
                }

            }
        }

        .block2-product-conan{
            padding-top: 35vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 5.33vw;
                    width: 91vw;

                    span{
                        font-size: 4.26vw;
                    }
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    width: 87vw;

                    .span-thin{
                        font-size: 4.8vw !important;
                    }

                    .span-bold{
                        font-family: RidleyGroteskBlack;
                    }

                    .br-desktop{
                        display: none;
                    }
                }

                p.last-paragraph{
                    margin-bottom: 10.66vw;
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }

                .grid-item{
                    .text-container{
                        flex-wrap: wrap;
                    }
    
                    .text-container .MuiGrid-root:first-child{
                        margin-right: 4.16vw;
                    }
                }
            }

            .desktop{
                display: none;
            }
        }

        .block3-product-conan{
            padding-top: 16vw;
            padding-bottom: 16vw;
            background-image: none;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 8vw !important;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }

                h3{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                }

            }
            
            .list-container{
                background-image: url('../../../assets/uploads/productMot/Fondo_mot.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 34.66vw;
                padding-bottom: 34.66vw;

                .vector-item-mot-pieza{
                    display: none;
                }
            }

            .img-vector-desktop{
                display: none;
            }

            .vector-item{
                margin-bottom: 0px;
                width: 100%;
                margin-top: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

            .vector-item-1, .vector-item-2{
                max-width: 81vw;
            }

            .vector-item-3{
                max-width: 71vw;
            }

            .vector-item-4{
                max-width: 88vw;
            }

            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                margin-left: 0px;

                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-conan{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
                        width: 87vw;
    
                        span.paragraph-thin{
                            font-size: 5.33vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Conan */

        /* Producto Tower */
        .block1-product-tower{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 80vw;
    
            .title-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 81vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 12vw !important;
                margin-bottom: -1vw !important;
                margin-right: 1.86vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 86vw;
            }

            br{
                display: none;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 50vw;
                    max-width: 60vw;
                    overflow: hidden;
                    margin: auto;
                    width: 60vw;
                    
                    img{
                        width: 100%;
                        height: auto;
                        position: initial;
                        right: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                    }
                }

            }
        }

        .block2-product-tower{
            padding-top: 59vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 5.33vw;
                    width: 45vw;

                    span{
                        font-size: 4.26vw;
                    }
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    width: 87vw;
                    margin-bottom: 10.66vw;

                    .span-thin{
                        color: $colorFour;
                        font-size: 6.93vw !important;
                    }

                    .span-bold{
                        color: $colorFour;
                        font-family: RidleyGroteskBlack;
                    }
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }
            }

            .desktop{
                display: none;
            }
        }

        .block3-product-tower{
            padding-top: 16vw;
            padding-bottom: 16vw;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 5.33vw !important;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }

            }

            .img-vector-desktop{
                display: none;
            }

            .vector-item{
                margin-bottom: 0px;
                width: 100%;
                margin-top: 0px;

                br{
                    display: none;
                }

                .span-margin-bottom{
                    margin-bottom: 5.33vw;
                }

                .span-margin-bottom-last{
                    margin-bottom: 10.66vw;
                }
        
                .span-margin-bottom-third{
                    margin-bottom: 5.33vw;
                }
        
                span.vector-text{
                    font-size: 5.33vw;
                    letter-spacing: 0.09px;
                    line-height: 5vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }
            }

            .vector-item-1, .vector-item-2{
                max-width: 81vw;
            }

            .vector-item-3{
                max-width: 71vw;
            }

            .vector-item-4{
                max-width: 88vw;
            }

            .vector-item-1, .vector-item-2, .vector-item-3, .vector-item-4{
                margin-left: 0px;

                div{
                    width: 100%;
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-tower{
            padding-top: 13.33vw;
            padding-bottom: 16vw;
    
            .grid-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                .grid-item:first-child{
                    width: 84vw;
                    max-width: 84vw;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 16vw;
                }
    
                .grid-item{
    
                    br{
                        display: none;
                    }
                    
                    h2{
                        font-size: 8vw;
                        line-height: 8vw;
                        margin-bottom: 8vw;
                        width: 100%;
    
                        span.paragraph-thin{
                            font-size: 4.26vw;
                        }
                    }
    
                    p{
                        width: 100%;
                        font-size: 6.93vw;
                        line-height: 7.2vw;
                    }
                }
            }
        }

        .block5-product-tower{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
                        width: 77vw;
    
                        span.paragraph-thin{
                            font-size: 5.33vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Tower */

        /* Producto Sentinel */
        .block1-product-sentinel{
            background-color: #ECF0F7;
            background-image: none;
            padding-top: 8vw;
            padding-bottom: 84vw;
    
            .title-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            h1{
                font-size: 17.06vw;
                line-height: 12.5vw;
                max-width: 60vw;
            }

            .title-grid{
                max-width: 60vw;
            }
    
            h1.last-title{
                margin-bottom: 2.66vw;
            }
    
            h2{
                font-size: 6.93vw;
                line-height: 7.4vw;

                br{
                    display: none;
                }
            }

            h2.title-h2 {
                width: 66vw;
                margin-bottom: 1.33vw;
            }

            h2.h2-text {
                width: 85vw;
                margin-bottom: 2.66vw;
            }
    
            h3{
                font-size: 5.86vw;
                letter-spacing: 0.1px;
                line-height: 5vw;
                margin-left: 15vw !important;
                margin-bottom: -1vw !important;
                margin-right: 1.86vw !important;
            }
    
            p{
                font-size: 4.26vw;
                letter-spacing: 0.07px;
                line-height: 5vw;
                margin-bottom: 0px;
                max-width: 86vw;
            }

            br{
                display: none;
            }
    
            button, a{
                display: none;
            }
    
            .grid-container{
                flex-direction: column;
                align-items: flex-start;
                position: relative;
    
                .grid-item:first-child{
                    position: relative;
                    max-width: 100%;
                }

                .img-desktop{
                    display: none;
                }
                
                .grid-item:last-child{
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 63vw;
                    max-width: 100%;
                    overflow: hidden;
                    margin: auto;
                    width: 98vw;
                    
                    img{
                        width: 100%;
                        height: auto;
                        position: initial;
                        right: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                    }
                }

            }
        }

        .block2-product-sentinel{
            padding-top: 16vw;
            padding-bottom: 16vw;

            
            .grid-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                .grid-item:last-child{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                }
                
                img.mobile-image{
                    width: 70.4vw;
                    margin: auto;
                    margin-bottom: 11.73vw;
                }
        
                h2{
                    font-size: 8vw;
                    line-height: 8.5vw;
                    margin-bottom: 5.33vw;
                    width: 45vw;

                    span{
                        font-size: 4.26vw;
                    }
                }
        
                p{
                    font-size: 6.93vw;
                    line-height: 7vw;
                    width: 87vw;
                    margin-bottom: 10.66vw;

                    .span-thin{
                        color: $colorFour;
                        font-size: 6.93vw !important;
                    }

                    .span-bold{
                        color: $colorFour;
                        font-family: RidleyGroteskBlack;
                    }
                }
        
                button{
                    height: 60px;
                    width: 260px;
                }

                .grid-item:first-child{
                    margin-right: 0vw;
                }
            }

            .desktop{
                display: none;
            }
        }

        .block3-product-sentinel{
            padding-top: 16vw;
            padding-bottom: 16vw;
    
            .grid-item{
                h2{
                    font-size: 8vw;
                    letter-spacing: 0.14px;
                    line-height: 9vw;
                    margin-bottom: 5.33vw;
                }
        
                p{
                    font-size: 6.93vw;
                    letter-spacing: 0.12px;
                    line-height: 7.5vw;
                    max-width: 100%;
                    margin-bottom: 5.33vw !important;
                    
                    span.paragraph-thin{
                        font-size: 6.93vw;
                    }
                }

            }

            .img-vector-desktop{
                display: none;
            }

            .vector-item{
                width: 100%;
                max-width: 100%;
                margin-top: 0px;
                width: max-content;
                max-width: max-content;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 4.02vw;

                br{
                    display: none;
                }
        
                span.vector-text{
                    font-size: 6.93vw;
                    letter-spacing: 0.09px;
                    line-height: 7vw;
                }

                div.third-vector-div{
                    margin-left: 0px;
                }
    
                .img-vector{
                    display: none;
                }

                .span-container{
                    width: 100% !important;
                    margin-right: 0px;
                }
    
                .last-vector-div{
                    margin-right: 0px !important;
                }

                .vector-image-container{
                    width: 6.67vw;
                    height: 5.33vw;
                    margin-right: 2.5vw;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            button{
                height: 50px;
                width: 220px;
            }
        }

        .block4-product-sentinel{
            height: 93.34vw;
            padding-bottom: 16vw;
            padding-top: 16vw;
    
            .grid-container{
                .grid-item{    
                    p{
                        font-size: 10.65vw;
                        line-height: 10.65vw;
                        margin-bottom: 10.66vw;
                        width: 77vw;
    
                        span.paragraph-thin{
                            font-size: 5.33vw;
                        }
                    }
    
                    button{
                        height: 50px;
                        width: 220px;
                    }
                }
            }
        }
        /* Fin Producto Sentinel */
    }

    @media(max-width: 767px){
        .block1-product-paok{
            .grid-container{
                img{
                    top: 87vw;
                }
            }
        }

        .block1-product-paok-elec{
            .grid-container{
                img{
                    top: 118vw;
                }
            }
        }
    }

    @media(max-width: 575px){
        .block1-product-paok{
            .grid-container{
                img{
                    top: 106vw;
                }
            }
        }
        .block2-product-paok{
            padding-top: 67vw;
        }

        .block1-product-paok-elec{
            .grid-container{
                img{
                    top: 134vw;
                }
            }
        }

        .block2-product-paok-elec{
            padding-top: 62vw;
        }
    }
    /* Fin Productos individuales */
}