.about{
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: left;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: left;
        -ms-flex-pack: left;
        -webkit-justify-content: left;
        justify-content: left;
        -webkit-box-align: left;
        -ms-flex-align: left;
        -webkit-align-items: left;
        align-items: left;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-container-pointer-events.swiper-container-vertical{
        height: 100vh;
    }

    .carousel1-about{
        background-image: url('../../../assets/uploads/Group_4.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        .carousel1-about-title{
            color: #FFFFFF;
            font-family: RidleyGroteskBlack;
            font-size: 100px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 93px;
        }
    
        .btn-carousel1-about{
            height: 60px;
            width: 180px;
            padding: 5px;
            margin-top: 40px;
        }
    }

    .carusel2-about {
        display: flex;
        width: 100%;
        justify-content: center;
        background-color: #FFFFFF;

        p:first-child{
            margin-bottom: 35px;
        }

        p {
            font-size: 46px;
            line-height: 46px;
            font-family: RidleyGroteskLight;
            color: $colorFour;
            text-align: left;
            margin-top: 0px;
            margin-bottom: 0px;
            font-weight: 300;

            span{
                font-family: RidleyGroteskBlack;
                color: $colorFive;
                text-decoration: underline;
            }
        }
    }

    .carousel3-about{
        width: 100%;
        background-color: #FFFFFF;

        .carousel3-title{
            margin-bottom: 4.6% !important;
        }

        .carousel3-img{
            height: auto;
            width: 532px;
            max-width: 100%;
            margin: auto;
        }
        
        h2{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 68px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 63px;
            text-align: start;
        }
    }

    .carousel-video{
        .video-container > div{
            height: 56.23vw !important;
        }

        .video-container{
            .react-player__preview{
                cursor: auto !important;
            }

            button{
                background: transparent;
                border: none;
                width: 8.47vw;
                opacity: 0.7;
                cursor: pointer;

                img{
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    
    .carousel4-about{
        width: 100%;
        display: flex;
        justify-content: center;
        
        p:first-child{
            margin-bottom: 35px;
        }

        p{
            color: $colorFour;
            font-family: RidleyGroteskLight;
            font-size: 46px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 46px;
            margin-bottom: 0px;
            margin-top: 0px;

            span{
                color: $colorFive;
                font-family: RidleyGroteskBlack;
                text-decoration: underline;
            }
        }

        .carousel4-about-grid-container{
            max-width: 75vw !important;
        }

        .carousel4-about-slider, .carousel4-about-list{
            h3{
                color: $colorFive;
                font-family: RidleyGroteskBlack;
                font-size: 48px;
                font-weight: 900;
                letter-spacing: 0;
                line-height: 46px;
            }

            p{
                color: $colorFour;
                font-family: RidleyGroteskRegular;
                font-size: 32px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 32px;
                text-decoration: underline;
            }
        }

        .carousel4-about-list{
            display: none;
        }
    }
    
    .carousel5-about{
        background: linear-gradient(180deg, #FFFFFF 0.09%, #D2DAEB 100%);

        .carousel5-about-grid-container{
            flex-wrap: nowrap;
        }

        h2{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 68px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 86px;
            text-decoration: underline;
            margin-top: 0px;
            margin-bottom: 6%;
        }

        .carousel5-img-container img{
            margin: auto;
            width: 1248px;
            height: auto;
            max-width: 100%;
        }

        @media(min-width: 901px){
            .carousel5-img-container{
                display: flex;
                justify-content: center;
            }
        }
    }

    .carousel6-about{
        width: 100%;
        display: flex;
        justify-content: center;
        background: linear-gradient(180deg, #D2DAEB 0.09%, #A7B6D8 100%);

        p:first-child{
            margin-bottom: 35px;
        }

        p{
            color: $colorFour;
            font-family: RidleyGroteskLight;
            font-size: 46px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 46px;
            margin-top: 0;
            margin-bottom: 0;

            span{
                font-family: RidleyGroteskBlack;
                color: $colorFive;
                text-decoration: underline;
            }
        }
    }

    .swiper-container-vertical>.swiper-pagination-bullets{
        top: 50%;
        transform: translate3d(0px,-50%,0);
        left: 69px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        width: max-content;
        position: fixed;
    }

    .swiper-pagination-bullet{
        background: #A7B6D8;
        width: 6px;
        height: 6px;
    }

    .swiper-pagination-bullet-active{
        background: $colorFour;
        width: 12px;
        height: 12px;
    }

    @media(min-width: 901px){
        .carousel5-about{
            padding-top: 20vh !important;
        }
        .carousel6-about{
            padding-bottom: 20vh !important;
        }
    }
}