@media(min-width: 901px){
    .px-lg-10-42{
        padding-left: 10.42%;
        padding-right: 10.42%;
    }
}

@media(max-width: 900px){
    .px-md-5-34{
        padding-left: 5.34%;
        padding-right: 5.34%;
    }

    .legal-text{
        .politica-cookies, .condiciones-compra, .aviso-legal{
            font-size: 13px;
            line-height: 19px;
        }
    }
}