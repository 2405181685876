.legal-text{
    .politica-cookies, .condiciones-compra, .aviso-legal{
        padding-top: 4vw;
        padding-bottom: 4vw;
        color: $colorFour;
        font-family: RidleyGroteskRegular;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        letter-spacing: 0px;
    }
}