.contact{
    .block1{
        background-image: url('../../../assets/uploads/Group_4_contact.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-bottom: 120px;
        padding-top: 130px;
        
        h2{
            color: #FFFFFF;
            font-family: RidleyGroteskBlack;
            font-size: 100px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 93px;
            max-width: 875px;
        }

        .text-row{
            margin-top: 120px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .text-block{
            max-width: 531px;
            min-width: 531px;
            
            p:first-child{
                margin-top: 0px;
            }

            p:last-child{
                margin: 0px;
            }
        }

        h3{
            font-family: RidleyGroteskBlack;  
        }

        p, h3{
            font-size: 36px;
            letter-spacing: 0;
            line-height: 38px;
            color: #FFFFFF;
        }

        p{
            font-family: RidleyGroteskRegular;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        .text-small{
            font-size: 18px;
            line-height: 18px;
            margin: 0px;
            margin-left: 6px;
        }
    
        .btn-block1{
            height: 60px;
            width: 180px;
            padding: 5px;
            margin-top: 40px;
        }

        .follow-block{
            margin-top: 72px;
            width: 174px;
            max-width: 174px;

            .follow-images{
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
            }
        }

        .img-geo-location{
            height: 78px;
            width: 49px;
        }

        .img-facebook, .img-instagram{
            height: 36px;
            width: 36px;
        }

        .img-twitter{
            height: 31px;
            width: 38px;
        }
    }

    .block2 {
        padding-bottom: 120px;
        padding-top: 120px;
        background: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);

        .input-text{
            width: 100%;
        }

        .input-text input, .input-select .MuiSelect-select, .input-select .MuiSelect-select em, .textarea-container textarea{
            border-color: #A7B6D8 !important;
            color: #2E3848;
            font-family: RidleyGroteskBold;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.08px;
            line-height: 18px;
            font-style: normal;
        }

        .input-select .MuiSelect-select, .input-select .MuiSelect-select em{
            display: flex;
            align-items: center;
        }

        .checkbox-container svg{
            box-sizing: border-box;
            height: 21px;
            width: 21px;
            border: 1px solid #A7B6D8;
            border-radius: 4px;
        }

        .checkbox-container path{
            display: none;
        }

        .checkbox-container .Mui-checked path{
            display: inline-block;
        }

        .checkbox-container .MuiTypography-root{
            color: #2E3848;
            font-size: 18px;
            letter-spacing: 0.08px;
            line-height: 18px;
        }

        .first-checkbox .MuiTypography-root{
            font-family: RidleyGroteskLight !important;
        }

        .checkbox-container .MuiTypography-root{
            font-family: RidleyGroteskBold;
        }

        .textarea-container textarea{
            padding: 14px;
        }

        h2{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 100px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 93px;
        }

        h4{
            color: $colorFour;
            font-family: RidleyGroteskRegular;
            font-size: 36px;
            letter-spacing: 0;
            line-height: 38px;
            font-weight: 300;
        }

        p{
            color: #2E3848;
            font-family: RidleyGroteskLight;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.08px;
            line-height: 18px;
        }
        
        .btn-block2{
            height: 60px;
            width: 180px;
            padding: 5px;
        }

        .button-container{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .checkbox-button-container{
            margin-top: 39px;
        }

        .input-column{
            width: 47.5%;
        }

        .input-column .MuiOutlinedInput-root{
            height: 58.88px;
        }

        .input-column #mui-component-select-status{
            color: #2E3848;
            font-family: RidleyGroteskBold;
            font-size: 18px;
            letter-spacing: 0.08px;
            line-height: 18px;
            display: flex;
            align-items: center;
        }

        @media(min-width: 900px){
            .textarea-container textarea::placeholder{
                color: #d6e0ee00;
            }
        }

    }
}