.homePage{
    .carouselContainerMobile{
        .carusel2, .carousel3, .carousel6{
            background-color: #273583;
        }

        .carusel2{
            background-color: #ffffff;
        }
    }
    .carousel1{
        @media(max-width: 1199px){
            .carousel1-title{
                font-size: 40px;
                line-height: 40px;
            }
        
            .btn-carousel1{
                height: 50px;
                width: 220px;
                margin-top: 20px;
            }
        }
    }

    .carusel2 {
        @media(max-width: 1199px){
            h2 {
                font-size: 30px;
                line-height: 34px;
            }
    
            p {
                font-size: 26px;
                line-height: 28px;
                margin-bottom: 20px;
            }
    
            .btn-carusel2{
                height: 51px;
                width: 221px;
            }
        }
    }

    .carousel3{
        @media(max-width: 1199px){
            p{
                font-size: 30px;
                line-height: 34px;
            }
    
            h2{
                font-size: 40px;
                line-height: 34px;
            }
        }
    }
    
    .carousel4{
        @media(max-width: 1199px){
            p{
                font-size: 30px;
                line-height: 34px;
            }

            img{
                height: 63px;
            }

            .carousel4-grid, .carousel4-list{
                h3{
                    font-size: 30px;
                    line-height: 34px;
                }
    
                p{
                    font-size: 26px;
                    line-height: 32px;
                }
            }
        }

        @media(max-width: 900px){
            .carousel4-grid, .carousel4-list{
                h3{
                    text-align: center;
                }
    
                p{
                    text-align: center;
                }
            }

            .carousel4-list{
                display: flex;
            }

            .carousel4-grid{
                display: none;
            }
        }
    }

    .carousel5{
        @media(max-width: 1199px){
            .btn-carousel5{
                height: 50px;
                width: 220px;
            }
    
            h2{
                font-size: 30px;
                line-height: 30px;
            }
    
            p{
                font-size: 26px;
                letter-spacing: 0.26px;
                line-height: 28px;
            }

            .title{
                width: 75%;
            }
        }

        @media(max-width: 900px){
            .carousel5-img{
                margin-top: 60px;
            }

            .carousel5-grid-container{
                flex-wrap: wrap;
            }
        }
    }

    .carousel6{
        @media(max-width: 1199px){
            h2{
                font-size: 30px;
                line-height: 34px;
                margin-bottom: 23px;
            }
    
            p{
                font-size: 26px;
                letter-spacing: 0.12px;
                line-height: 34px;
                margin-bottom: 30px;
            }
        }

        @media(max-width: 900px){
            .marcas-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;

                .marcas-item{
                    margin-bottom: 20px;
                }
                
                h3{
                    font-size: 26px;
                    letter-spacing: 0.12px;
                    line-height: 28px;
                }
            }
        }
    }
    
    /* Slider Responsive */
    @media(max-width: 900px){
        .mySwiper{
            display: none !important;
        }
    }
    /* End Slider Responsive */

    @media(min-width: 901px){
        .px-lg-150px{
            padding-left: 150px;
            padding-right: 150px;
        }
    }

    @media(max-width: 900px){
        .align-content-md-end{
            align-content: flex-end;
        }

        .mb-md-40px{
            margin-bottom: 40px;
        }

        .px-md-20px{
            padding-left: 20px;
            padding-right: 20px;
        }

        .py-md-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .swiper-container-vertical>.swiper-pagination-bullets{
            display: none;
        }
    }

    @media(max-width: 576px){
        .px-xs-10px{
            padding-left: 10px;
            padding-right: 10px;
        }

        .py-xs-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .align-content-xs-end{
            align-content: flex-end;
        }

        .mb-xs-40px{
            margin-bottom: 40px;
        }

        .h-xs-auto{
            height: auto !important;
        }
    }

}