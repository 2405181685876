.contact{
    .block1{
        @media(max-width: 1199px){
            h2{
                font-size: 40px;
                line-height: 40px;
                max-width: 336px;
            }

            .text-block{
                min-width: auto;
            }

            p, h3{
                font-size: 26px;
                line-height: 28px;
            }

            .geo-location-container{
                margin-top: 40px;
            }
        }
        @media(max-width: 900px){
            padding-bottom: 120px;
            padding-top: 120px;

            .text-row{
                margin-top: 60px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .text-block{
                p:first-child{
                    margin-top: 0px;
                }

                p:last-child{
                    margin: 0px;
                }
            }

            .geo-location-container{
                display: none;
            }
        
            .btn-block1{
                height: 50px;
                width: 220px;
                margin-top: 60px;
            }

            .follow-block{
                margin-top: 60px;
                width: 174px;
                max-width: 174px;

                .follow-images{
                    margin-top: 30px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .img-facebook, .img-instagram{
                height: 22px;
                width: 22px;
            }

            .img-twitter{
                height: 19px;
                width: 23px;
            }
        }
    }

    .block2{
        @media(max-width: 1199px){
            .input-text input, .input-text input::placeholder, .MuiPaper-root li, .input-select .MuiSelect-select, .input-select .MuiSelect-select em, .textarea-container textarea{
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 14px;
                font-family: RidleyGroteskLight;
                opacity: 1;
            }

            .textarea-container textarea::placeholder{
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 14px;
                font-family: RidleyGroteskLight;
                opacity: 1;
            }

            .checkbox-container .MuiTypography-root{
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 14px;
            }

            h2{
                font-size: 40px;
                line-height: 40px;
            }

            h4{
                font-size: 26px;
                line-height: 28px;
            }

            p{
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 14px;
            }
        }

        @media(max-width: 900px){
            padding-bottom: 60px;
            padding-top: 60px;
            background: #ECF0F7;
            
            .checkbox-item{
                margin-top: 24px;
            }
            
            .btn-block2{
                height: 50px;
                width: 220px;
            }

            .button-container{
                justify-content: flex-start;
                margin-top: 28px;
            }

            .checkbox-button-container{
                margin-top: 39px;
            }

            .input-column{
                width: 100%;
            }

            .input-select:last-child{
                margin-top: 30px;
            }
        }

        @media(max-width: 899px){
            .textarea-container .label{
                display: none;
            }

            .textarea-container textarea::placeholder{
                color: #2E3848 !important;
            }
        }
    }

    @media(min-width: 901px){
        .px-lg-150px{
            padding-left: 150px;
            padding-right: 150px;
        }
    }

    @media(max-width: 900px){
        .align-content-md-end{
            align-content: flex-end;
        }

        .mb-md-40px{
            margin-bottom: 40px;
        }

        .px-md-20px{
            padding-left: 20px;
            padding-right: 20px;
        }

        .py-md-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .mt-md-30px{
            margin-top: 30px;
        }
    }

    @media(max-width: 576px){
        .px-xs-10px{
            padding-left: 10px;
            padding-right: 10px;
        }

        .py-xs-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .align-content-xs-end{
            align-content: flex-end;
        }

        .mb-xs-40px{
            margin-bottom: 40px;
        }

        .h-xs-auto{
            height: auto !important;
        }
    }

}