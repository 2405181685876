.about{
    .carousel1-about{
        @media(max-width: 1199px){
            .carousel1-about-title{
                font-size: 40px;
                line-height: 40px;
            }
        
            .btn-carousel1-about{
                height: 50px;
                width: 220px;
                margin-top: 20px;
            }
        }
    }

    .carusel2-about {
        @media(max-width: 1199px){
            p {
                font-size: 26px;
                line-height: 28px;
                margin-bottom: 20px;
            }
        }
    }

    .carousel3-about{
        @media(max-width: 1199px){
            h2{
                font-size: 30px;
                line-height: 34px;
            }
        }

        @media(max-width: 900px){
            .carousel3-title{
                margin-bottom: 60px !important;
            }
        }
    }

    .carousel-video{
        .video-container{
            button{
                width: 17.07vw;
            }
        }
    }
    
    .carousel4-about{
        @media(max-width: 1199px){
            p{
                font-size: 26px;
                line-height: 28px;
            }
        }
    }

    .carousel5-about{
        @media(max-width: 1199px){
            h2{
                font-size: 30px !important;
                line-height: 34px;
            }
        }

        @media(max-width: 900px){
            .carousel5-img-container{
                overflow-x: auto;
            }
            .carousel5-img-container img{
                width: auto;
                max-width: none;
                height: 999px;
                max-height: calc(100vh - 132px) !important;
            }
            h2{
                margin-bottom: 60px;
            }
        }
    }

    .carousel6-about{
        @media(max-width: 1199px){
            h2{
                font-size: 30px;
                line-height: 34px;
                margin-bottom: 23px;
            }
    
            p{
                font-size: 26px;
                letter-spacing: 0;
                line-height: 28px;
                margin-bottom: 60px;
            }
        }
    }
    
    /* Slider Responsive */
    @media(max-width: 900px){
        .mySwiper{
            display: none !important;
        }
    }
    /* End Slider Responsive */

    @media(min-width: 901px){
        .px-lg-150px{
            padding-left: 150px;
            padding-right: 150px;
        }
    }

    @media(max-width: 900px){
        .align-content-md-end{
            align-content: flex-end;
        }

        .mb-md-40px{
            margin-bottom: 40px;
        }

        .px-md-20px{
            padding-left: 20px;
            padding-right: 20px;
        }

        .py-md-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .swiper-container-vertical>.swiper-pagination-bullets{
            display: none;
        }

        .carousel1-about, .carusel2-about, .carousel3-about, .carousel4-about, .carousel5-about, .carousel6-about{
            height: auto;
        }
    }

    @media(max-width: 576px){
        .px-xs-10px{
            padding-left: 10px;
            padding-right: 10px;
        }

        .py-xs-60px{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .align-content-xs-end{
            align-content: flex-end;
        }

        .mb-xs-40px{
            margin-bottom: 40px;
        }

        .h-xs-auto{
            height: auto !important;
        }
    }

}