@font-face {
  font-family: RidleyGroteskBlackItalic;
  src: url("../assets/font/Ridley-Grotesk-Black-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskBlack;
  src: url("../assets/font/Ridley-Grotesk-Black.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskBoldItalic;
  src: url("../assets/font/Ridley-Grotesk-Bold-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskBold;
  src: url("../assets/font/Ridley-Grotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskExtraBoldItalic;
  src: url("../assets/font/Ridley-Grotesk-ExtraBold-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskExtraBold;
  src: url("../assets/font/Ridley-Grotesk-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskItalic;
  src: url("../assets/font/Ridley-Grotesk-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskLightItalic;
  src: url("../assets/font/Ridley-Grotesk-Light-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskLight;
  src: url("../assets/font/Ridley-Grotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskMediumItalic;
  src: url("../assets/font/Ridley-Grotesk-Medium-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskMedium;
  src: url("../assets/font/Ridley-Grotesk-Medium.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskRegular;
  src: url("../assets/font/Ridley-Grotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskSemiBold;
  src: url("../assets/font/Ridley-Grotesk-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskSemiBoldItalic;
  src: url("../assets/font/Ridley-Grotesk-SemiBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskThinItalic;
  src: url("../assets/font/Ridley-Grotesk-Thin-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskThin;
  src: url("../assets/font/Ridley-Grotesk-Thin.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskUltraLightItalic;
  src: url("../assets/font/Ridley-Grotesk-UltraLight-Italic.otf") format("opentype");
}

@font-face {
  font-family: RidleyGroteskUltraLight;
  src: url("../assets/font/Ridley-Grotesk-UltraLight.otf") format("opentype");
}