.products{
    width: 100%;

    /* Lista Productos */
    .block1-products{
        background-image: url('../../../assets/uploads/Group_2.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;

        .grid-item{
            max-width: 875px;
        }

        img{
            width: 119px;
            height: auto;
            object-fit: cover;
            object-position: center;
        }

        h1{
            color: #FFFFFF;
            font-family: RidleyGroteskBlack;
            font-size: 100px;
            line-height: 93px;
            margin: 0px;
        }
    }

    .block2-products {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #FFFFFF;
        padding-top: 120px;
        padding-bottom: 30px;
        padding-left: calc(10.42% - 2.09vw);
        padding-right: calc(10.42% - 2.09vw);

        .image-container{
            max-width: 37.5vw;
            padding-left: 2.09vw;
            padding-right: 2.09vw;
            position: relative;

            .title-img{
                position: absolute;
                padding-right: 4.18vw;
                width: 74.63%;
                padding-top: 5.56%;
                padding-left: 5.56%;
            }

            img{
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

    }
    /* Fin Lista Productos */

    /* Producto Anti Okupas */
    .block1-product-paok{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 5vw;
        padding-bottom: 12.77vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: right;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            /*position: absolute;
            top: 0;*/
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;/*2.6vw*/
            margin-bottom: 2.77vw;
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        max-width: 88.31%;
                    }
                }
            }

            img{
                position: absolute;
                right: 1.2vw;
                top: 16vw;/*22vw*/
                width: 52.5vw;
            }
        }
    }

    .block2-product-paok{
        padding-top: 27vw;
        padding-bottom: 10.41vw;
        background-color: #FFFFFF;

        img.key-image{
            width: 21.45vw;
        }

        img.icon-product{
            width: 3.12vw;
        }

        span{
            color: #2E3848;
            font-family: RidleyGroteskRegular;
            font-size: 3.33vw;
            letter-spacing: 0;
            line-height: 3.4vw;
        }

        button{
            height: 60px;
            width: 260px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;

            .grid-item:first-child{
                margin-right: 6.24vw;
            }

            .list-container{
                .icon-product-container{
                    margin-right: 1.38vw;
                    margin-top: 0.35vw;
                }
            }

            .list-container.list-container-notlast{
                margin-bottom: 2.08vw;
            }

            .list-container.list-container-last{
                margin-bottom: 2.77vw;
            }

        }
    }

    .block3-product-paok{
        padding-top: 8.33vw;
        padding-bottom: 26.04vw;
        background-image: url('../../../assets/uploads/productPaok/grav_fondo3.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 3vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 43vw;
                margin-bottom: 14.72vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }
                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }

        .span-margin-bottom{
            margin-bottom: 1.38vw;
        }

        .span-margin-bottom-third{
            margin-bottom: -2.29vw;
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        .vector-item-1{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;

            div{
                width: 14.23vw;
            }
            img{
                width: 35.83vw;
            }
        }

        .vector-item-2{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 10.55vw;

            div.span-container{
                width: 20.07vw;
            }
            img{
                width: 32.15vw;
            }
        }

        .vector-item-3{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 16.87vw;

            div.third-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;

                div.span-container{
                    width: 12.91vw;
                }
                img{
                    width: 22.91vw;
                }
            }

        }

        .vector-item-4{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            div.last-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-end;
                margin-right: 8.33vw;

                div.span-container{
                    width: 14.23vw;
                }
                img{
                    width: 19.09vw;
                }
            }
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-paok{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 33.95vw;
                max-width: 33.95vw;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 31.73vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 21.11vw;
                object-fit: cover;
            }
        }
    }

    .block5-product-paok{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Anti Okupas */

    /* Producto Anti Okupas Electrónica */
    .block1-product-paok-elec{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 3vw;
        padding-bottom: 8.61vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: right;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
            width: 32vw;
        }

        h2.h2-text{
            width: 45vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            /*position: absolute;
            top: 0;*/
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;/*2.6vw*/
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 32vw;
                    }
                }
            }

            img{
                position: absolute;
                right: 1.2vw;
                top: 16vw;/*22vw*/
                width: 52.5vw;
            }
        }
    }

    .block2-product-paok-elec{
        padding-top: 24vw;
        padding-bottom: 8.81vw;
        background-color: #FFFFFF;

        
        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            
            img.mobile-image{
                width: 34.72vw;
            }
    
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                letter-spacing: 0;
                line-height: 4.2vw;
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }

            @media(min-width: 901px){
                .mobile-display{
                    display: none !important;
                }
            }
        }
    }

    .block3-product-paok-elec{
        padding-top: 8.33vw;
        padding-bottom: 26.04vw;
        background-image: url('../../../assets/uploads/productPaokElectronica/grav_fondo_2.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 3vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 66vw;
                margin-bottom: 14.72vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }

        .span-margin-bottom{
            margin-bottom: 0.97vw;
        }

        .span-margin-bottom-third{
            margin-bottom: -1.8vw;
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        .vector-item-1{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;

            div{
                width: 28vw;
            }
            img{
                width: 35.83vw;
            }
        }

        .vector-item-2{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 10.55vw;

            div.span-container{
                width: 17vw;
            }
            img{
                width: 32.15vw;
            }
        }

        .vector-item-3{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 16.87vw;

            div.third-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
                margin-left: 3vw;

                div.span-container{
                    width: 13vw;
                }
                img{
                    width: 22.98vw;
                }
            }

        }

        .vector-item-4{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            div.last-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-end;
                margin-right: 12.5vw;

                div.span-container{
                    width: 10vw;
                }
                img{
                    width: 13.68vw;
                }
            }
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-paok-elec{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 35.41vw;
                max-width: 35.41vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 29.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block5-product-paok-elec{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Anti Okupas Electrónica */

    /* Producto Reja */
    .block1-product-reja{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 3vw;
        padding-bottom: 8.61vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
            width: 32vw;
        }

        h2.h2-text{
            width: 27vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 7vw !important;
            /*position: absolute;
            top: 0;*/
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;/*2.6vw*/
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 20vw;
                    }
                }
            }

            img{
                position: absolute;
                right: 3vw;
                top: 124px;/*22vw*/
                width: 42vw;
            }
        }
    }

    .block2-product-reja{
        padding-top: 22.84vw;
        padding-bottom: 22.84vw;
        background-image: url('../../../assets/uploads/productReja/Fondo_reja.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 3vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 75vw;
                margin-bottom: 7.01vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }

        .span-margin-bottom{
            margin-bottom: 1.45vw;
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        .vector-item-1{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            margin-bottom: 5.55vw;
            width: 71vw;
            max-width: 71vw;

            div{
                width: 30vw;
            }
            img{
                width: 32.15vw;
            }
        }

        .vector-item-2{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            margin-bottom: 5.55vw;
            width: 56vw;
            max-width: 56vw;

            div.span-container{
                width: 25.5vw;
            }
            img{
                width: 27.08vw;
            }
        }

        .vector-item-3{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 5.55vw;

            div.third-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-end;
                width: 63vw;
                max-width: 63vw;

                div.span-container{
                    width: 31vw;
                }
                img{
                    width: 32.15vw;
                }
            }

        }

        .vector-item-4{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            div.last-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-end;
                width: 50vw;
                max-width: 50vw;

                div.span-container{
                    width: 30.5vw;
                }
                img{
                    width: 31.87vw;
                }
            }
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block3-product-reja{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 33.61vw;
                max-width: 33.61vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 29.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block4-product-reja{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Reja */

    /* Producto Door */
    .block1-product-door{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 16vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
            width: 32vw;
        }

        h2.h2-text{
            width: 32vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 7.5vw !important;
            /*position: absolute;
            top: 0;*/
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;/*2.6vw*/
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 32vw;
                    }
                }
            }

            img{
                position: absolute;
                right: 0vw;
                top: 13vw;/*22vw*/
                width: 47vw;
            }
        }
    }

    .block2-product-door{
        padding-top: 12vw;
        padding-bottom: 8.81vw;
        background-color: #FFFFFF;

        
        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;

                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                    color: $colorFour;
                }
                span.paragraph-thin{
                    color: $colorFour;
                }
                @media(min-width: 901px){
                    span.paragraph-thin{
                        font-size: 1.66vw;
                    }
                }
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }
    }

    .block3-product-door{
        padding-top: 17.84vw;
        padding-bottom: 30.62vw;
        background-image: url('../../../assets/uploads/productDoor/Fondo_door.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 3vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 73vw;
                margin-bottom: 7.08vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }

        .span-margin-bottom{
            margin-bottom: 0.97vw;
        }

        .span-margin-bottom-third{
            margin-bottom: -1.8vw;
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        .vector-item-1{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            max-width: 58.5vw;

            div{
                width: 14vw;
                margin-right: 2vw;
            }
            img{
                width: 17.77vw;
            }
        }

        .vector-item-2{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 11.94vw;
            margin-left: 3vw;
            margin-top: -3.5vw;

            div.span-container{
                width: 17vw;
            }
            img{
                width: 32.15vw;
            }
        }

        .vector-item-3{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 3.4vw;

            div.third-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
                margin-left: 4vw;

                div.span-container{
                    width: 20vw;
                }
                img{
                    width: 22.98vw;
                }
            }

        }

        .vector-item-4{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;

            div.last-vector-div{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-end;
                margin-right: 10vw;

                div.span-container{
                    width: 16vw;
                }
                img{
                    width: 17.49vw;
                }
            }
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-door{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 35.41vw;
                max-width: 35.41vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 29.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block5-product-door{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Door */

    /* Producto Gravity */
    .block1-product-gravity{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 24vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
        }

        h2.h2-text{
            width: 35vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 7.5vw !important;
            /*position: absolute;
            top: 0;*/
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;/*2.6vw*/
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 32vw;
                    }
                }
            }

            .grid-item:last-child{
                position: absolute;
                right: 11vw;
                top: 32vw;
                width: 66vw;
                max-width: 100%;
                
                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }

            @media(max-width: 1200px){
                .grid-item:last-child{
                    right: 9vw;
                    top: 36vw;
                }
            }
        }
    }

    .block2-product-gravity{
        padding-top: 24vw;
        padding-bottom: 8.81vw;
        background-color: #FFFFFF;

        
        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }

            h2{
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                color: $colorFour;
                width: 24vw;
                line-height: 3.5vw;
                margin-bottom: 1vw;

                span{
                    font-family: RidleyGroteskLight;
                    font-size: 2.36vw;
                    color: #3262AB;
                }
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                width: 39vw;

                span.paragraph-underline{
                    text-decoration: underline;
                }
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }
    }

    .block3-product-gravity{
        padding-top: 8.33vw;
        padding-bottom: 9.02vw;
        background-image: url('../../../assets/uploads/productGravity/grav_fondo_3.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 3vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 73vw;
                margin-bottom: 12.58vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        .vector-item-1{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            max-width: 66.5vw;

            div{
                width: 11vw;
                margin-bottom: 1.94vw;
                margin-right: -0.5vw;
            }
            img{
                width: 10.76vw;
            }
        }

        .vector-item-2{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 4.94vw;
            margin-left: 29.5vw;
            margin-top: -5.5vw;

            div.span-container{
                width: 21vw;
                margin-bottom: 1.94vw;
            }
            img{
                width: 23.26vw;
            }
        }

        .vector-item-3{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            margin-bottom: 0px;
            max-width: 73.5vw;

            div.span-container{
                width: 16vw;
                margin-top: 1.15vw;
                margin-left: 10vw;
            }
            img{
                width: 15.83vw;
            }

        }

        .vector-item-4{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-end;
            margin-top: -8.5vw;
            max-width: 53.8vw;

            div.span-container{
                width: 20vw;
                margin-top: 1.15vw;
            }
            img{
                width: 16.66vw;
            }
        }

        @media(max-width: 1024px){
            p{
                margin-bottom: 17.58vw !important;
            }

            .vector-item-1{
                max-width: 69.5vw;
    
                div{
                    width: 11vw;
                    margin-bottom: 1.94vw;
                    margin-right: -0.5vw;
                }
            }
    
            .vector-item-2{
                margin-bottom: 4.94vw;
                margin-left: 31.5vw;
                margin-top: -5.5vw;
    
                div.span-container{
                    width: 21vw;
                    margin-bottom: 1.94vw;
                }
            }
    
            .vector-item-3{
                margin-bottom: 0px;
                max-width: 75.5vw;
    
                div.span-container{
                    width: 16vw;
                    margin-top: 1.15vw;
                    margin-left: 10vw;
                }
    
            }
    
            .vector-item-4{
                margin-top: -10.5vw;
                max-width: 54.8vw;
    
                div.span-container{
                    width: 20vw;
                    margin-top: 1.15vw;
                }
            }
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
            .vector-item-mobile{
                display: none;
            }
        }
    }

    .block4-product-gravity{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 35.41vw;
                max-width: 35.41vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 29.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block5-product-gravity{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Gravity */

    /* Producto Mot */
    .block1-product-mot{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 9.5vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
        }

        h2.h2-text{
            width: 35vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 8.5vw !important;
            /*position: absolute;
            top: 0;*/
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;/*2.6vw*/
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 16vw;
                    }
                }
            }

            .grid-item:last-child{
                position: absolute;
                right: 11vw;
                top: 22vw;
                width: 41vw;
                max-width: 100%;
                
                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }

            @media(max-width: 1200px){
                .grid-item:last-child{
                    right: 9vw;
                    top: 36vw;
                }
            }
        }
    }

    .block2-product-mot{
        padding-top: 12.91vw;
        padding-bottom: 8.81vw;
        background-color: #FFFFFF;

        
        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }

            h2{
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                color: $colorFour;
                width: 24vw;
                line-height: 3.5vw;
                margin-bottom: 1vw;

                span{
                    font-family: RidleyGroteskLight;
                    font-size: 2.36vw;
                    color: #3262AB;
                }
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                width: 39vw;

                span.paragraph-underline{
                    text-decoration: underline;
                }
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }

        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }
    }

    .block3-product-mot{
        padding-top: 16.25vw;
        padding-bottom: 42.77vw;
        background-image: url('../../../assets/uploads/productMot/Fondo_mot.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 4vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 80vw;
                margin-bottom: 15.97vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        @media(min-width: 901px) and (max-width: 1015px){
            .vector-item-2{
                top: 6vw !important;
            }
        }
        @media(min-width: 901px){
            .list-container{
                position: relative;

                .vector-item-mot-pieza{
                    width: 12.22vw;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;

                    img{
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .vector-item{
                    width: max-content;
                    max-width: max-content;
                    position: absolute;
                }

                .vector-item-1{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    top: -5vw;
                    right: 22.5vw;
        
                    div{
                        width: 23vw;
                        margin-bottom: 0.94vw;
                        margin-right: -6vw;
                    }
                    img{
                        width: 17.77vw;
                    }
                }
        
                .vector-item-2{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    top: 7vw;
                    left: 8.5vw;
        
                    div.span-container{
                        width: 21vw;
                        margin-bottom: -2.94vw;
                    }
                    img{
                        width: 31.38vw;
                    }
                }
        
                .vector-item-3{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    right: 8.5vw;
                    top: 5vw;
        
                    div.span-container{
                        width: 16vw;
                        margin-bottom: 0.94vw;
                        text-align: end;
                    }
                    img{
                        width: 26.94vw;
                    }
        
                }
            }
            
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-mot{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 35.41vw;
                max-width: 35.41vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 29.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block5-product-mot{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Mot */

    /* Producto Alarma */
    .block1-product-alarma{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 20vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h1.small-title{
            font-size: 5.55vw;
            line-height: 5vw;
            margin-bottom: -1vw;
            margin-left: 10.5vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
        }

        h2.h2-text{
            width: 65vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -2.3vw !important;
            margin-left: 0vw !important;
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 16vw;
                    }
                }
            }

            .grid-item:last-child{
                position: absolute;
                right: 1vw;
                top: 32vw;
                width: 68vw;
                max-width: 100%;
                
                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }

            @media(max-width: 1200px){
                .grid-item:last-child{
                    top: 36vw;
                }
            }
        }
    }

    .block2-product-alarma{
        padding-top: 15vw;
        padding-bottom: 4.16vw;
        background-color: #FFFFFF;

        
        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }

            h2{
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                color: $colorFour;
                width: 24vw;
                line-height: 3.5vw;
                margin-bottom: 1vw;

                span{
                    font-family: RidleyGroteskLight;
                    font-size: 2.36vw;
                    color: #3262AB;
                }
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                width: 43vw;

                span.span-bold{
                    font-family: RidleyGroteskBlack;
                    color: $colorFour;
                }
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }

        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }
    }

    .block3-product-alarma{
        padding-top: 4.16vw;
        padding-bottom: 4.16vw;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 4vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 80vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        @media(min-width: 901px) and (max-width: 1015px){
            .vector-item-2{
                top: 6vw !important;
            }
        }
        @media(min-width: 901px){
            .list-container{
                position: relative;

                .vector-item-mot-pieza{
                    width: 12.22vw;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;

                    img{
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .vector-item{
                    width: max-content;
                    max-width: max-content;
                    position: absolute;
                }

                .vector-item-1{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    top: -5vw;
                    right: 22.5vw;
        
                    div{
                        width: 23vw;
                        margin-bottom: 0.94vw;
                        margin-right: -6vw;
                    }
                    img{
                        width: 17.77vw;
                    }
                }
        
                .vector-item-2{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    top: 7vw;
                    left: 8.5vw;
        
                    div.span-container{
                        width: 21vw;
                        margin-bottom: -2.94vw;
                    }
                    img{
                        width: 31.38vw;
                    }
                }
        
                .vector-item-3{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    right: 8.5vw;
                    top: 5vw;
        
                    div.span-container{
                        width: 16vw;
                        margin-bottom: 0.94vw;
                        text-align: end;
                    }
                    img{
                        width: 26.94vw;
                    }
        
                }
            }
            
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-alarma{
        padding-top: 4.16vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 35.41vw;
                max-width: 35.41vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 37.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block5-product-alarma{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Alarma */

    /* Producto Conan */
    .block1-product-conan{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 23.5vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h1.small-title{
            font-size: 5.55vw;
            line-height: 5vw;
            margin-bottom: -1vw;
            margin-left: 10.5vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 0.69vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
        }

        h2.h2-text{
            width: 38vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 7.5vw !important;
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;
    
                    p{
                        width: 29vw;
                    }
                }
            }

            .grid-item:last-child{
                position: absolute;
                right: 10vw;
                top: 33vw;
                width: 44vw;
                max-width: 100%;
                
                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }

            @media(max-width: 1200px){
                .grid-item:last-child{
                    top: 36vw;
                }
            }
        }
    }

    .block2-product-conan{
        padding-top: 15vw;
        padding-bottom: 4.16vw;
        background-color: #FFFFFF;

        
        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }

            h2{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                margin-bottom: 1vw;
                font-weight: 400;
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskLight;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                width: 22vw;

                span.span-thin{
                    font-family: RidleyGroteskLight;
                    color: $colorFour;
                    font-size: 1.66vw;
                }

                span.span-bold{
                    font-family: RidleyGroteskBlack;
                    color: $colorFour;
                }
            }
    
            .grid-item{
                .text-container{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .text-container .MuiGrid-root:first-child{
                    margin-right: 4.16vw;
                }
            }

            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }

        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }
    }

    .block3-product-conan{
        padding-top: 4.16vw;
        padding-bottom: 4.16vw;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 4vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                width: 100vw;
                max-width: 80vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }

            h3{
                color: $colorFive;
                font-family: RidleyGroteskBold;
                font-size: 2.5vw;
                font-weight: bold;
                letter-spacing: 0.16px;
                line-height: 3vw;
            }
        }
        
        span.vector-text{
            color: $tertiary;
            font-family: RidleyGroteskBlack;
            font-size: 2.08vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 2vw;
        }

        @media(min-width: 901px) and (max-width: 1015px){
            .vector-item-2{
                top: 6vw !important;
            }
        }
        @media(min-width: 901px){
            .list-container{
                position: relative;

                .vector-item-mot-pieza{
                    width: 12.22vw;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;

                    img{
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .vector-item{
                    width: max-content;
                    max-width: max-content;
                    position: absolute;
                }

                .vector-item-1{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    top: -5vw;
                    right: 22.5vw;
        
                    div{
                        width: 23vw;
                        margin-bottom: 0.94vw;
                        margin-right: -6vw;
                    }
                    img{
                        width: 17.77vw;
                    }
                }
        
                .vector-item-2{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    top: 7vw;
                    left: 8.5vw;
        
                    div.span-container{
                        width: 21vw;
                        margin-bottom: -2.94vw;
                    }
                    img{
                        width: 31.38vw;
                    }
                }
        
                .vector-item-3{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    right: 8.5vw;
                    top: 5vw;
        
                    div.span-container{
                        width: 16vw;
                        margin-bottom: 0.94vw;
                        text-align: end;
                    }
                    img{
                        width: 26.94vw;
                    }
        
                }
            }
            
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-conan{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Conan */

    /* Producto Tower */
    .block1-product-tower{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 17vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;/*4vw*/
            margin-bottom: 2.77vw;
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
        }

        h2.h2-text{
            width: 35vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 7vw !important;
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 32vw;
                    }
                }
            }

            .grid-item:last-child{
                position: absolute;
                right: 11vw;
                top: 10vw;
                width: 35vw;
                max-width: 100%;
                
                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .block2-product-tower{
        padding-top: 12.91vw;
        padding-bottom: 8.81vw;
        background-color: #FFFFFF;

        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }

            h2{
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                color: $colorFour;
                width: 24vw;
                line-height: 3.5vw;
                margin-bottom: 1vw;

                span{
                    font-family: RidleyGroteskLight;
                    font-size: 2.36vw;
                    color: #3262AB;
                }
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                width: 43vw;

                span.span-thin{
                    font-size: 1.66vw;
                    color: $colorFour;
                }

                span.span-bold{
                    font-family: RidleyGroteskBlack;
                    color: $colorFour;
                }
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }

        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }
    }

    .block3-product-tower{
        padding-top: 16.25vw;
        padding-bottom: 42.77vw;
        background-image: url('../../../assets/uploads/productTower/Fondo_conan.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 4vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 80vw;
                margin-bottom: 4.16vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }
        
        span.vector-text{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 2.5vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 3vw;

            span.vector-number{
                color: $colorFour;
            }
        }
        
        @media(min-width: 901px){
            .list-container{
                position: relative;

                .vector-item{
                    width: max-content;
                    max-width: max-content;
                    position: absolute;
                }

                .vector-item-1{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    top: 0vw;
                    right: 0vw;
        
                    div{
                        width: 50vw;
                        margin-bottom: 0.94vw;
                        margin-right: -6vw;
                    }
                    img{
                        width: 17.77vw;
                    }
                }
        
                .vector-item-2{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    top: 13.5vw;
                    left: 0vw;
        
                    div.span-container{
                        width: 49vw;
                        margin-bottom: -2.94vw;
                    }
                    img{
                        width: 31.38vw;
                    }
                }
        
                .vector-item-3{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    right: -8vw;
                    top: 24vw;
        
                    div.span-container{
                        width: 52vw;
                        margin-bottom: 0.94vw;
                    }
                    img{
                        width: 26.94vw;
                    }
        
                }
            }
            
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-tower{
        padding-top: 8.33vw;
        padding-bottom: 8.33vw;

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            .grid-item:first-child{
                width: 35.41vw;
                max-width: 35.41vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5.06vw;
            }

            .grid-item{

                h2{
                    color: $colorFour;
                    font-family: RidleyGroteskBlack;
                    font-size: 4.16vw;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 4.3vw;
                    margin-bottom: 2.77vw;
                    width: 29.5vw;

                    span.paragraph-thin{
                        font-size: 2.36vw;
                        font-weight: 100;
                        font-family: RidleyGroteskLight;
                    }
                }

                p{
                    width: 38.19vw;
                    color: #2E3848;
                    font-family: RidleyGroteskRegular;
                    font-size: 2.5vw;
                    letter-spacing: 0;
                    line-height: 3vw;

                    span.paragraph-underline{
                        text-decoration: underline;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .block5-product-tower{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Tower */

    /* Producto Sentinel */
    .block1-product-sentinel{
        background-image: linear-gradient(180deg, #FFFFFF 0%, #D4DFEF 100%);
        padding-top: 6vw;
        padding-bottom: 26vw;

        h1{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 9vw;/*11.11vw*/
            font-weight: 900;
            letter-spacing: 0;
            line-height: 7vw;/*8vw*/
            text-align: left;
            margin-bottom: 0px;
        }

        h1.last-title{
            margin-bottom: 2.08vw;
        }

        h2{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 3.16vw;/*4.16vw*/
            font-weight: 900;
            letter-spacing: 0.28px;
            line-height: 3vw;
            margin-bottom: 0.69vw
        }

        h2.title-h2{
            text-align: end;
            margin-bottom: 0px;
        }

        h2.h2-text{
            width: 45vw;
        }

        h3{
            color: $colorFive;
            font-family: RidleyGroteskLight;
            font-size: 1.77vw;/*2.77vw*/
            font-weight: 300;
            letter-spacing: 0.18px;
            line-height: 2vw;
            width: max-content;
            margin-bottom: -1.3vw !important;
            margin-left: 8.5vw !important;
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskLight;
            font-size: 2vw;/*2.5vw*/
            font-weight: 300;
            letter-spacing: 0.16px;
            line-height: 2.1vw;
            margin-bottom: 2.77vw;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        button{
            height: 60px;
            width: 180px;
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            height: max-content;

            .grid-item:first-child{
                position: relative;
            }
            
            @media(min-width: 901px){
                .grid-item:first-child{
                    max-width: 30vw;/*36.87vw*/
    
                    p{
                        width: 32vw;
                    }
                }

                .img-mobile{
                    display: none;
                }
            }

            .grid-item:last-child{
                position: absolute;
                right: 9vw;
                top: 31.5vw;
                width: 60vw;
                max-width: 100%;
                
                img{
                    width: 100%;
                    height: auto;
                    position: initial;
                    right: 0;
                    top: 0;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .block2-product-sentinel{
        padding-top: 8.33vw;
        padding-bottom: 4.16vw;
        background-color: #FFFFFF;
        
        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }

        .grid-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            
            img.mobile-image{
                width: 34.72vw;
            }

            h2{
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                color: $colorFour;
                width: 24vw;
                line-height: 3.5vw;
                margin-bottom: 1vw;

                span{
                    font-family: RidleyGroteskLight;
                    font-size: 2.36vw;
                    color: #3262AB;
                }
            }
    
            p{
                color: #2E3848;
                font-family: RidleyGroteskRegular;
                font-size: 3.33vw;
                letter-spacing: 0;
                line-height: 3.4vw;
                width: 43vw;

                span.span-thin{
                    font-size: 1.66vw;
                    color: $colorFour;
                }

                span.span-bold{
                    font-family: RidleyGroteskBlack;
                    color: $colorFour;
                }
            }
    
            button{
                height: 60px;
                width: 260px;
            }

            .grid-item:first-child{
                margin-right: 1.52vw;
            }
        }

        @media(min-width: 901px){
            .mobile{
                display: none;
            }
        }
    }

    .block3-product-sentinel{
        padding-top: 4.16vw;
        padding-bottom: 4.33vw;
        background-color: #FFFFFF;

        .grid-item{
            h2{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
                font-size: 4.16vw;
                font-weight: 900;
                letter-spacing: 0.28px;
                line-height: 4vw;
                text-decoration: underline;
                margin-bottom: 2.77vw;
            }
    
            p{
                color: $tertiary;
                font-family: RidleyGroteskRegular;
                font-size: 2.5vw;
                letter-spacing: 0.11px;
                line-height: 3vw;
                text-decoration: underline;
                max-width: 80vw;
                margin-bottom: 4.16vw;
    
                span.paragraph-bold{
                    font-family: RidleyGroteskBlack;
                    letter-spacing: 0.16px;
                }

                span.paragraph-thin{
                    font-size: 1.59vw;
                }
            }
        }
        
        span.vector-text{
            color: $colorFive;
            font-family: RidleyGroteskBlack;
            font-size: 2.5vw;
            font-weight: 900;
            letter-spacing: 0.14px;
            line-height: 3vw;

            span.vector-number{
                color: $colorFour;
            }
        }
        
        @media(min-width: 901px){
            .list-container{
                position: relative;

                .vector-item{
                    width: max-content;
                    max-width: max-content;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 4.02vw;

                    .vector-image-container{
                        height: 2.08vw;
                        width: 2.63vw;
                        margin-right: 1.4vw;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
            }
            
        }

        @media(min-width: 901px){
            a, button{
                display: none;
            }
        }
    }

    .block4-product-sentinel{
        background-color: $colorFour;
        height: 41.67vw;
        padding-bottom: 2.77vw;
        padding-top: 2.77vw;
        display: flex;
        align-items: center;

        .grid-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: center;

            .grid-item{
                max-width: 100%;

                p{
                    color: #FFFFFF;
                    font-family: RidleyGroteskLight;
                    font-size: 6.25vw;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 6vw;
                    margin-bottom: 2.77vw;

                    span.paragraph-thin{
                        font-size: 3.12vw;
                    }

                    span.paragraph-bold{
                        font-family: RidleyGroteskBlack;
                    }
                }

                button{
                    height: 61px;
                    width: 181px;
                }
            }
        }
    }
    /* Fin Producto Sentinel */
}