$primary: #283582;
$primaryOpacity: rgba(80, 70, 228, 0.2);

$secondary: #009EE2;
$tertiary: #333B87;
$colorFour: #273583;
$colorFive: #3362AC;

$font-light: #fff;
$font-grey: #778;
$font-dark: #000;

$border-light: #fff;
$border-grey-light: #a7a7a7;
$boder-grey: #444444;

$background-light: #fff;
$background-grey-light: #eee;
$background-grey: #a7a7a7;
$background-grey-dark: #363636;
$background-dark: #151515;
$background-dark-absolute: #000;
$background-blue-light: #d6dfee;

$success: #69af00;
$danger: #ff5a5f;
