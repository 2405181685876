/* ESTILOS HEADER */
.header {
    @media (max-width: 900px) {
        .help .email {
            display: none;
        }

        .logo {
            width: 215.87px;
            height: 30px;
            padding-left: 20px;
        }

        .help {
            border-bottom: 1px solid #F0F0F0;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100vw;
            left: 0px;
        }
    }
}
