.homePage{
    .swiper {
        width: 100%;
        height: 100%;
    }
    
    .carusel2{
        background-color: #FFFFFF;
    }

    .carousel3, .carousel6{
        background-color: #273583;
    }

    .swiper-slide {
        text-align: left;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: left;
        -ms-flex-pack: left;
        -webkit-justify-content: left;
        justify-content: left;
        -webkit-box-align: left;
        -ms-flex-align: left;
        -webkit-align-items: left;
        align-items: left;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-container-pointer-events.swiper-container-vertical{
        height: 100vh;
    }

    .carousel1{
        background-image: url('../../../assets/uploads/Render-dorso_GRAV_web.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;

        .carousel1-title{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 100px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 93px;
        }
    
        .btn-carousel1{
            height: 60px;
            width: 180px;
            padding: 5px;
            margin-top: 40px;
        }
    }

    .carusel2 {
        display: flex;
        width: 100%;
        justify-content: center;

        h2 {
            font-size: 68px;
            line-height: 63px;
            font-family: RidleyGroteskBlack;
            color: #273583;
            text-decoration: underline;
            text-align: left;
            margin-bottom: 0px;
        }

        p {
            font-size: 36px;
            line-height: 42px;
            font-family: RidleyGroteskRegular;
            color: #273583;
            text-align: left;
            margin-top: 18px;
            margin-bottom: 40px;
        }

        .btn-carusel2{
            height: 61px;
            width: 281px;
            padding: 5px;
        }
    }

    .carousel3{
        display: flex;
        width: 100%;
        justify-content: center;

        h2 {
            font-size: 68px;
            line-height: 63px;
            font-family: RidleyGroteskBlack;
            color: #FFFFFF;
            text-align: left;
            margin-bottom: 0px;
            span{
                text-decoration: underline;
            }
        }

        p {
            font-size: 36px;
            line-height: 42px;
            font-family: RidleyGroteskRegular;
            color: #FFFFFF;
            text-align: left;
            margin-top: 18px;
            margin-bottom: 40px;
            span{
                text-decoration: underline;
                font-family: RidleyGroteskBlack;
            }
        }

        .btn-carusel2{
            height: 61px;
            width: 281px;
            padding: 5px;
        }
    }
    
    .carousel4{
        width: 100%;
        display: flex;
        justify-content: center;
        background-image: url('../../../assets/uploads/Group_10_2.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        
        p{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 6.3vh;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 63px;
            text-align: center;

            span{
                color: $colorFive;
                text-decoration: underline;
            }

            .span-bold{
                color: $colorFour;
                font-family: RidleyGroteskBlack;
            }
        }

        img{
            height: 5.77vw;
            object-fit: cover;
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }

        .btn-carusel4{
            height: 60px;
            width: 180px;
            background-color: #3362AC;
            border-radius: 4px;
            color: #FFFFFF;
            font-family: RidleyGroteskRegular;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            text-transform: initial;
        }

        @media(min-width: 901px){
            .carousel4-grid-container{
                max-width: 75vw !important;
            }
        }

        .carousel4-grid, .carousel4-list{
            h3{
                color: $colorFive;
                font-family: RidleyGroteskBlack;
                font-size: 2.92vw;
                font-weight: 900;
                letter-spacing: 0;
                line-height: 44px;
                text-align: center;
            }

            p{
                color: $colorFour;
                font-family: RidleyGroteskRegular;
                font-size: 2.23vw;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 2.2vw;
                text-decoration: underline;
                margin-top: 15px;
            }

            .carousel4-card{
                max-width: 20vw !important;

                .icons-titles{
                    margin-bottom: 5px;
                }
            }

            .carousel4Swiper{
                width: 100%;

                .swiper-wrapper{
                    padding-left: 94px;
                    padding-right: 94px;
                    
                    .swiper-slide{
                        &:not(:last-child){
                            width: auto !important;
                        }
                    }

                    .last-slide{
                        width: 94px !important;
                    }
                }
            }
        }

        .carousel4-list{
            display: none;
        }
    }

    .carousel5{
        .carousel5-grid-container{
            flex-wrap: nowrap;
        }

        .btn-carousel5{
            height: 60px;
            width: 280px;
            padding: 5px;
        }

        h2{
            color: $colorFour;
            font-family: RidleyGroteskBlack;
            font-size: 68px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 63px;

            span{
                text-decoration: underline;
            }
        }

        p{
            color: $tertiary;
            font-family: RidleyGroteskRegular;
            font-size: 36px;
            font-weight: 400;
            letter-spacing: 0.16px;
            line-height: 42px;

            span{
                color: $colorFive;
                font-family: RidleyGroteskBlack;
                text-decoration: underline;
            }
        }

        .title, .second-text{
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 60px;
        }

        .first-text{
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 40px;
        }

        img{
            display: block;
            width: 90%;
            height: auto;
            object-fit: cover;
        }
    }

    @media(min-width: 1900px){
        .carousel6 > div{
            max-width: 65vw;
        }
    }

    .carousel6{
        width: 100%;
        display: flex;
        justify-content: center;
        
        h2{
            color: #FFFFFF;
            font-family: RidleyGroteskRegular;
            font-size: 68px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 63px;
            margin-bottom: 57px;

            span{
                font-family: RidleyGroteskBlack;
                font-weight: 900;

                .text-decoration-underline{
                    text-decoration: underline;
                }
            }
        }

        p{
            color: #FFFFFF;
            font-family: RidleyGroteskRegular;
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0.13px;
            line-height: 33px;
            margin-bottom: 7.17vh;

            span{
                font-family: RidleyGroteskBlack;
            }
        }

        .marcas-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;

            h3{
                margin: 0px;
                font-size: 1.95vw;
                letter-spacing: 0.13px;
                line-height: 2vw;
                color: #FFFFFF
            }

            .marcas-item{
                h3{
                    font-family: RidleyGroteskBlack;
                    font-weight: 900;
                }
            }

            .marcas-item-otros{
                h3{
                    font-family: RidleyGroteskRegular;
                    font-weight: 400;
                }
            }
        }
    }

    .swiper-container-vertical>.swiper-pagination-bullets{
        top: 50%;
        transform: translate3d(0px,-50%,0);
        left: 69px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        width: max-content;
        position: fixed;
    }

    .swiper-pagination-bullet{
        background: #A7B6D8;
        width: 6px;
        height: 6px;
    }

    .swiper-pagination-bullet-active{
        background: $colorFour;
        width: 12px;
        height: 12px;
    }

    @media(min-width: 901px){
        /* Slider Responsive */
        .carouselContainerMobile{
            display: none !important;
        }
        /* End Slider Responsive */
    }
}